<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">Dalin Store</h2>
        </b-link>

        <b-card-title class="mb-1"> Welcome to Dalin Store! 👋 </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="login">
            <!-- email -->
            <b-form-group :label="$t('field.email')" label-for="login-email">
              <validation-provider
                vid="email"
                #default="{ errors }"
                :name="$t('field.email')"
                rules="required"
              >
                <b-form-input
                  id="login-email"
                  v-model="data.email"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('field.password') }}</label>
                <b-link :to="{ name: 'forget-password' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                vid="password"
                #default="{ errors }"
                :name="$t('field.password')"
                rules="required"
              >
                <n-password-input
                  v-model="data.password"
                  id="password"
                  name="password"
                  placeholder="············"
                  :errors="errors"
                ></n-password-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="data.status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
              :disabled="invalid"
              block
            >
              {{ $t('general.login') }}
            </n-button-loading>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'
import NPasswordInput from '@/components/NPasswordInput'
import NButtonLoading from '@/components/NButtonLoading'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    NPasswordInput,
    NButtonLoading,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      data: {
        email: '',
        password: '',
        status: '',
      },
      loading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true
          useJwt
            .login({
              ...this.data,
            })
            .then((response) => {
              const data = response.data.data
              const userData = data.user
              useJwt.setToken(data.accessToken)
              useJwt.setRefreshToken(data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$store.commit('profile/UPDATE_PROFILE', userData)

              let newAbility = [...initialAbility]
              if (userData.ability) {
                if (userData.isReadonly) {
                  userData.ability.forEach((x) => {
                    x.action = 'read'
                    return x
                  })
                }

                newAbility = [...userData.ability, ...initialAbility]
              }
              this.$ability.update(newAbility)

              this.$router
                .replace(getHomeRouteForLoggedInUser('admin'))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.name || userData.email}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged!`,
                    },
                  })
                })
            })
            .catch((error) => {
              this.loading = false
              if (error.response?.status === 422) {
                this.$refs.loginForm.setErrors(error.response.data.message)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
